import React, { useState, useEffect } from "react";
import { client, urlFor } from "./client";
import Navbar from "./Containers/Navbar/Navbar";
import HeroSection from "./Containers/HeroSection/HeroSection";
import RowCard from "./Components/RowCard/RowCard";
import Skills from "./Containers/Skills/Skills";
import Gallery from "./Containers/Gallery/Gallery";
import Contact from "./Containers/Contact/Contact";
import Footer from "./Containers/Footer/Footer";
import Loader from "./Components/Loader/Loader";

const App = () => {
  const [heroSectionData, setHeroSectionData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [contactData, setContactData] = useState(null);
  const [skillsData, setSkillsData] = useState(null);
  const [expData, setExpData] = useState(null);
  const [galleryData, setGalleryData] = useState(null);
  const [showLoader, setShowLoader] = useState(true);

  useEffect(() => {
    const hero_query = '*[_type == "headline"]';
    client.fetch(hero_query).then((data) => setHeroSectionData(data[0]));

    const about_query = '*[_type == "about"]';
    client.fetch(about_query).then((data) => setAboutData(data[0]));

    const contact_query = '*[_type == "contact"]';
    client.fetch(contact_query).then((data) => setContactData(data[0]));

    const galley_query = '*[_type == "gallery"]';
    client.fetch(galley_query).then((data) => setGalleryData(data));

    const skills_query = '*[_type == "skills"]';
    client.fetch(skills_query).then((data) => setSkillsData(data));

    const exp_query = '*[_type == "exp"]';
    client.fetch(exp_query).then((data) => setExpData(data));

    setTimeout(() => {
      setShowLoader(false);
    }, 2500);
  }, []);
  // {data && <img src={urlFor(aboutData.about_image)} alt={data[0].name} />}

  return (
    <>
      {showLoader && <Loader />}

      <Navbar />
      <main>
        <HeroSection
          name={heroSectionData && heroSectionData.name}
          src={heroSectionData && urlFor(heroSectionData.main_image)}
          description={heroSectionData && heroSectionData.description}
        />

        {/* ABOUT SECTION */}
        <RowCard
          // imgURL={aboutData && urlFor(aboutData.about_image)}
          imgDataArr={
            aboutData && [
              urlFor(aboutData.about_image1),
              urlFor(aboutData.about_image2),
              urlFor(aboutData.about_image3),
            ]
          }
          alt="pankaj"
          highlightText="PERSONAL DETAILS"
          capsText="ABOUT ME"
          subTitleText={aboutData && aboutData.description}
          link="#about"
          btnText="VIEW FULL DETAILS"
          btnLink="#gallery"
        />

        <Skills
          skillsData={skillsData && skillsData}
          expData={expData && expData}
        />

        <Gallery galleryData={galleryData && galleryData} />

        <Contact
          email={contactData && contactData.email}
          phone={contactData && contactData.phone}
          location={contactData && contactData.location}
        />
      </main>

      <Footer
        email={contactData && contactData.email}
        phone={contactData && contactData.phone}
        location={contactData && contactData.location}
      />
    </>
  );
};

export default App;
