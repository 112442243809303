import React from "react";
import "./RowCard.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const RowCard = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 2,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 6000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <section className="hero_section row_card_section" id="about">
      <div style={{ display: "grid", placeContent: "center" }}>
        <div className="about_slider__continer">
          <Slider {...settings}>
            {props.imgDataArr &&
              props.imgDataArr.map((el, ind) => {
                return (
                  <div key={ind} className="image__card_container">
                    <img src={el} className="img_card" alt={ind} />
                  </div>
                );
              })}
          </Slider>
        </div>
      </div>

      <div className="user__content">
        <span className="caps__title">{props.capsText}</span>
        <span className="highlight__title">{props.highlightText}</span>
        <span className="sub__title" style={{ textAlign: "justify" }}>
          {props.subTitleText}
        </span>
        <a className="btn" href={props.btnLink}>
          {props.btnText}
        </a>
      </div>
    </section>
  );
};

export default RowCard;
