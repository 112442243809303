import React, { useEffect, useState } from "react";
import Heading from "../../Components/Heading/Heading";
import "./Skills.css";
import ImgCard from "../../Components/ImgCard/ImgCard";
import { urlFor } from "../../client";

const Skills = (props) => {
  const [expData, setExpData] = useState([]);

  const sortDataBasedOnYear = (data) => {
    return data.sort((a, b) => a.year - b.year);
  };

  useEffect(() => {
    if (props.expData) {
      setExpData(sortDataBasedOnYear(props.expData));
    }
  }, [props.expData]);

  return (
    <section className="skills_section" id="skills">
      <Heading
        highlightText="Skills & Experiences"
        subTitleText="Explore some of my skills and experience"
      />

      <div className="skills_exp_container">
        <div className="skills_img_cards_container">
          {props.skillsData &&
            props.skillsData.map((el, ind) => {
              return (
                <ImgCard
                  key={ind}
                  src={urlFor(el.imgUrl)}
                  alt={el.alt}
                  skillName={el.skillName}
                />
              );
            })}
        </div>

        <div className="skills_experience_container">
          {expData &&
            expData.map((el, ind) => {
              return (
                <div className="experience_card" key={ind}>
                  <span className="exp__year">{el.year}</span>
                  <div className="role_company_box">
                    <span className="exp_role">{el.role}</span>
                    <span className="exp_company">{el.company}</span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Skills;
