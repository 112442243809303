import React from "react";
import "./Gallery.css";
import Heading from "../../Components/Heading/Heading";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { urlFor } from "../../client";

const Gallery = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="gallery_section" id="gallery">
      <Heading
        highlightText="Gallery"
        subTitleText="Pankaj Nandwani Images Collections"
      />

      <Slider {...settings}>
        {props.galleryData &&
          props.galleryData.map((el, ind) => {
            return (
              <div key={ind} className="image__card_container">
                <img src={urlFor(el.img)} className="img_card" alt={el.alt} />
              </div>
            );
          })}
      </Slider>
    </section>
  );
};

export default Gallery;
