import React from "react";
import "./ImgCard.css";

const ImgCard = (props) => {
  return (
    <div className="skills_img__card">
      <div className="circular__cover">
        <img src={props.src} alt={props.alt} />
      </div>
      <span>{props.skillName}</span>
    </div>
  );
};

export default ImgCard;
