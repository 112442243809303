import React from "react";
import "./HeroSection.css";

const HeroSection = (props) => {
  return (
    <section className="hero_section" id="home">
      <div className="user__content">
        <span className="caps__title">THIS IS ME</span>
        <span className="highlight__title">{props.name}</span>
        <span className="sub__title">{props.description}</span>
        <a className="btn" href="#gallery">
          DISCOVER MORE
        </a>
      </div>
      <div className="user__image">
        <img src={props.src} alt={props.name} />
      </div>
    </section>
  );
};

export default HeroSection;
